<template>
  <div>
    <footer class="contacts">
      <ul class="contacts-links">
        <li><a target="_blank" href="https://dobry-academia.ru/rules.pdf">Правила акции</a></li>
        <li><a href="/faq#feedback">Обратная связь</a></li>
        <li><a target="_blank" href="https://dobry-academia.ru/agreement.pdf">Пользовательское соглашение</a></li>
      </ul>
      <div class="contacts-info">
        <p class="rules">
          Общий срок проведения Акции с 01.05.2022 года по 28.02.2023 года. Срок регистрации чеков для участия в Акции:
          с 01.05.2022 года по 28.02.2023 года (включительно). Срок вручения призов до 31 марта 2023года. Информацию
          об организаторе акции, условиях участия, правилах проведения, призах, их количестве, сроках, месте и порядке
          их получения узнавайте на сайте <a href="http://dobry.ru/academy">http://dobry.ru/academy</a>. © 2022. АО «Мултон». Все права защищены.
        </p>
      </div>
    </footer>
    <footer class="contacts-mob">
      <p>
        Общий срок проведения Акции с 01.05.2022 года по 28.02.2023 года. Срок регистрации чеков для участия в Акции:
        с 01.05.2022 года по 28.02.2023 года (включительно). Срок вручения призов до 31 марта 2023года. Информацию
        об организаторе акции, условиях участия, правилах проведения, призах, их количестве, сроках, месте и порядке
        их получения узнавайте на сайте <a href="http://dobry.ru/academy">http://dobry.ru/academy</a>. © 2022. АО «Мултон». Все права защищены.
      </p>
      <div><a target="_blank" href="https://dobry-academia.ru/rules.pdf">Правила акции</a></div>
      <div><a href="/faq#feedback">Обратная связь</a></div>
      <div><a target="_blank" href="https://dobry-academia.ru/agreement.pdf">Пользовательское соглашение</a></div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.contacts {
  background-color: #F6F6F6;
  position: relative;
  height: 330px;
  box-sizing: border-box;
  padding: 3.5% 8% 0 8%;
  //background-image: url("../assets/svg/landing-purple-rectangle-small.svg");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.contacts-mob {
  display: none;
}

.contacts-info {
  margin-top: 60px;
  display: flex;
  column-gap: 20px;
  text-transform: none;
}

.contacts-links {
  display: flex;
  column-gap: 90px;
  font-size: 24px;
  font-weight: 400;
  list-style: none;
  padding: 0;

  a {
    text-decoration: none;
    color: #3F3F3F;
  }
}

.rules {
  margin: 0;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #3F3F3F;

  a {
    text-decoration: none;
    color: #3F3F3F;
  }
}

@media screen and (max-width: $xxxl) {
  .contacts-info {
    margin: 0;
  }
}


@media screen and (max-width: $xl) {
  .contacts-links {
    font-size: 20px;
  }

  .contacts-info p {
    font-size: 12px;
  }
}

@media screen and (max-width: $lg) {
  .contacts {
    display: none;
  }

  .contacts-mob {
    position: relative;
    display: block;
    margin-top: -24px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 40px;
    padding-bottom: 40px;
    //background-image: url("../assets/svg/landing-purple-rectangle-mob.svg");
    background-size: cover;
    text-align: center;

    p {
      font-family: "Montserrat";
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
      color: #3F3F3F;
      text-transform: none;
    }

    a {
      text-decoration: none;
      color: #3F3F3F;
    }

    div {
      margin: 15px 0 15px 0;
    }

    img {
      margin: 15px 8px 0 8px;
    }
  }
}
</style>