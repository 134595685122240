<template>
  <modal @close="close">
    <h2>Восстановление доступа</h2>
    <form class="login__form" action="#" method="post">
      <div class="login__input-group">
        <input v-model="email"
            class="login__input" id="email" name="email" type="email" placeholder="E-mail">
        <div class="login__button-group">
          <button @click.prevent="forgot" class="login__button login__button-main" type="submit">Продолжить</button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import LargePopup from '@/components/popups/layouts/LargePopup.vue';
import { resendPassword } from "@/api/index"

export default {
  name: "ForgotPopup",
  components: {
    Modal: LargePopup
  },

  data() {
    return {
      email: ""
    }
  },

  methods: {
    close() {
      this.$store.dispatch("bus/closePopup", "forgot")
    },

    async forgot() {
      const response = await resendPassword(this.email)

      if (response.error === 1) {
        this.$store.dispatch("bus/notifyError", {
          title: "Произошла ошибка",
          message: response.message.email[0]
        })

        return
      }

      this.$store.dispatch("bus/notifySuccess", {
        title: "Успешно",
        message: "На указанную почту был выслан пароль"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

$input-border-color: #DBDBDB;
$input-bg-color: #FFFFFF;
$input-text-color: #666;

.error-hint {
  font-size: 12px;
  font-weight: 400;
  font-family: "Marvin Round";
  color: #3F3F3F;
}

.login {
  &__form {
    text-align: center;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin: 10px 0 10px 0;
  }

  &__input {
    box-sizing: border-box;
    width: 376px;
    height: 60px;
    padding-left: 30px;
    border: 2px solid $input-border-color;
    background-color: $input-bg-color;
    font-family: "Montserrat";
    font-size: 20px;
    border-radius: 50px;
    color: $input-text-color;
  }

  &__input-error {
    border-color: $error-color;
  }

  &__button-group {
    padding-bottom: 60px;

    button {
      display: block;
    }
  }

  &__forgot-password {
    margin-bottom: 10px;
  }

  &__button {
    width: 376px;
    height: 60px;
    font-family: "Marvin Round";
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }

  &__button-main {
    background-color: $purple;
    color: $white;

    &:hover {
      background-color: $purple;
    }
  }

  &__button-secondary {
    margin-top: 20px;
    background-color: $white;
    color: $purple;
    border: 3px solid $purple;
  }
}

@media screen and (max-width: $lg) {
  .login {
    &__input-group {
      row-gap: 10px;
    }

    &__input {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      font-size: 16px;
    }

    &__forgot-password {
      font-size: 18px;
    }

    &__button-group {
      width: 100%;
    }

    &__button {
      width: 100%;
      height: 50px;
      font-size: 14px;
    }
  }
}
</style>