<template>
  <div class="wrapper">
    <sidebar-layout />
    <slot></slot>
    <footer-layout />
  </div>
</template>

<script>
import Sidebar from "@/layouts/Sidebar"
import Footer from "@/layouts/Footer"

export default {
  name: "BaseLayout",
  components: {
    SidebarLayout: Sidebar,
    FooterLayout: Footer
  }
}
</script>

<style scoped>
.wrapper {
  font-family: "Marvin Round";
  text-transform: uppercase;
}
</style>