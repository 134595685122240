<template>
  <nav class="navigation">
    <router-link class="logo-container" to="/">
<!--      <img class="logo" src="../assets/svg/logo.svg" alt="Логотип">
      <img class="super-academy" src="../assets/svg/super-academy.svg" alt="Академия супер">-->
    </router-link>
    <div style="visibility: hidden; width: 20%; margin-right: auto;"></div>
    <ul class="nav-links">
      <li class="nav-link">
        <router-link to="/">Главная</router-link>
      </li>
      <li class="nav-link">
        <router-link to="/winners">Победители</router-link>
      </li>
      <li class="nav-link">
        <router-link to="/faq">FAQ</router-link>
      </li>
      <li v-show="this.$store.getters['user/isLoggedIn']" class="nav-link">
        <router-link to="/prizes">Призы</router-link>
      </li>
    </ul>
    <div class="nav-button-block">
      <a @click.prevent="showLoginPopup" class="landing-button login-button" href="#">Личный кабинет</a>
      <a v-show="this.$store.getters['user/isLoggedIn']" @click.prevent="logout" class="landing-button logout-button" href="#">Выйти</a>
    </div>
    <div class="menu-container-mob">
      <a class="login-button-mob" href="#">
        <img
            @click="showLoginPopup"
            src="../assets/svg/user-icon.svg" alt="Логин">
      </a>
      <img @click="toggleMenu"
           class="menu-icon-mob" :src="getMenuIcon()" alt="Меню">
    </div>
    <ul v-show="isMenuOpened" class="nav-links-mob" id="nav-links-mob">
      <li class="nav-link">
        <router-link to="/">Главная</router-link>
      </li>
      <li class="nav-link">
        <router-link to="/winners">Победители</router-link>
      </li>
      <li class="nav-link">
        <router-link to="/faq">FAQ</router-link>
      </li>
      <li v-show="this.$store.getters['user/isLoggedIn']" class="nav-link">
        <router-link to="/prizes">Призы</router-link>
      </li>
    </ul>
    <login-popup v-show="getPopupStatus('login')" />
    <register-popup v-show="getPopupStatus('register')" />
    <forgot-popup v-show="getPopupStatus('forgot')" />
  </nav>
</template>

<script>
import LoginPopup from "@/components/popups/LoginPopup"
import RegisterPopup from "@/components/popups/RegisterPopup"
import ForgotPopup from "@/components/popups/ForgotPopup"
import { capitalize } from "@/utils/helpers"
import store from "@/store";

export default {
  name: "Sidebar",
  components: {
    LoginPopup,
    RegisterPopup,
    ForgotPopup
  },

  data() {
    return {
      isMenuOpened: false
    }
  },

  methods: {
    toggleMenu() {
      this.isMenuOpened = ! this.isMenuOpened
    },

    getMenuIcon() {
      if (! this.isMenuOpened) {
        return require("../assets/svg/menu-icon.svg");
      }

      return require("../assets/svg/close-icon.svg");
    },

    getPopupStatus(name) {
      return this.$store.state.bus.popups[`is${capitalize(name)}Opened`]
    },

    showLoginPopup() {
      if (this.$store.getters["user/isLoggedIn"]) {
        this.$router.push("/personal/profile")

        return
      }

      this.$store.dispatch("bus/openPopup", "login")
    },

    eraseAll() {
      this.$store.state.bus.toggler = ! this.$store.state.bus.toggler
    },

    async logout() {
      localStorage.removeItem("token")
      await store.dispatch("user/clear")
      this.$router.push("/")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

a {
  text-decoration: none;
}

.navigation {
  position: relative;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #F6F6F6;

  .super-academy {
    margin: 0 1.5% 0 1.5%;
  }
}

.logo-container {
  display: flex;
}

.nav-links, .steps, .contacts-links, .nav-links-mob {
  padding: 0;
  list-style-type: none;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  margin: 0 15px 0 15px;
  font-size: 18px;
  font-weight: 400;

  a {
    color: $green;

    &:active {
      color: $purple;
    }
  }

  &:first-child {
    //margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}

.nav-button-block {
  display: flex;
  margin-left: auto;

  .login-button {
    margin-right: 15px;
  }
}

.logout {
  cursor: pointer;
}

.logout-button {
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  color: #3F3F3F;
  background-color: #BABABA;
  padding: 0 20px 0 20px !important;
}

.landing-button {
  padding: 0 20px 0 60px;
  box-sizing: border-box;
  font-weight: 400;
  text-align: center;
  border-radius: 56px;
  white-space: nowrap;
}

.login-button {
  position: relative;
  height: 60px;
  font-size: 15px;
  line-height: 60px;
  color: #3F3F3F;
  background-color: #BABABA;

  &::before {
    content: "";
    position: absolute;
    width: 45px;
    height: 45px;
    top: 7px;
    left: 7px;
    background-image: url("../assets/svg/user-icon.svg");
  }
}

.menu-container-mob {
  display: none;
}

.login-button-mob img {
  width: 36px;
  height: 36px;
}

.menu-icon-mob {
  width: 32px;
  height: 18px;
  cursor: pointer;
}

@media screen and (max-width: $xl) {
  .logo {
    width: 50%;
    height: 50%;
  }

  .navigation .super-academy {
    width: 50%;
    height: 50%;
  }

  .nav-link {
    font-size: 14px;
  }

  .login-button {
    font-size: 12px;
  }
}

@media screen and (max-width: $lg) {
  .navigation {
    height: 50px;
    justify-content: space-between;

    .logo-container {
      column-gap: 10%;
    }

    .logo, .super-academy {
      width: 40%;
      height: 40%;
    }
  }

  .logout-button {
    background-color: transparent;
  }

  .nav-links, .login-button {
    display: none;
  }

  .menu-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 10px;
  }

  .nav-links-mob {
    width: 100%;
    margin: 0;
    padding: 15px;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: $white;
    z-index: 999;

    .nav-link {
      margin: 0;
      font-size: 18px;
      line-height: 34px;
    }
  }
}

img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>