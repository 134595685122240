<template>
  <modal @close="close">
    <h2>Вход</h2>
    <form @submit.prevent="login" class="login__form" action="#" method="post">
      <div class="login__input-group">
        <input
            @keydown="removeError('email')"
            v-model="email" :class="{ 'login__input-error': hasError('email') }"
            class="login__input" id="email" name="email" type="text" placeholder="E-mail">
        <div v-show="hasError('email')" class="error-hint">{{ errors.email }}</div>
        <input
            @keydown="removeError('password')"
            v-model="password"  :class="{ 'login__input-error': hasError('password') }"
            class="login__input" id="password" name="password" type="password" placeholder="Пароль">
        <div v-show="hasError('password')" class="error-hint">{{ errors.password }}</div>
        <a @click.prevent="forgot" class="login__forgot-password" href="#">Забыли пароль?</a>
        <div class="login__button-group">
          <button class="login__button login__button-main" type="submit">Войти</button>
          <button @click.prevent="switchPopup" class="login__button login__button-secondary">Зарегистрироваться</button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import LargePopup from '@/components/popups/layouts/LargePopup.vue';

export default {
  name: "LoginPopup",
  components: {
    Modal: LargePopup
  },

  data(){
    return {
      email : "",
      password : "",

      errors: {
        email: false,
        password: false
      }
    }
  },

  methods: {
    close() {
      this.$store.dispatch("bus/closePopup", "login")
    },

    switchPopup() {
      this.$store.dispatch("bus/closePopup", "login")
      this.$store.dispatch("bus/openPopup", "register")
    },

    hasError(field) {
      return this.errors[field]
    },

    removeError(field) {
      this.errors[field] = false
    },

    validate() {
      let error = false

      if (this.email === "") {
        this.errors.email = error = "Поле E-mail является обязательным"
      }

      if (this.password === "") {
        this.errors.password = error = "Поле пароль является обязательным"
      }

      return ! error
    },

    async login() {
      if (! this.validate()) {
        return
      }

      const request = {
        login: this.email,
        password: this.password
      }

      const response = await this.$store.dispatch("user/signIn", request)

      if (response.error == 1) {
        const message = response.message

        if ("login" in message) {
          this.errors.email = message.login[0]
        }

        if ("password" in message) {
          this.errors.password = message.password[0]
        }
      }
    },

    forgot() {
      this.$store.dispatch("bus/closePopup", "login")
      this.$store.dispatch("bus/openPopup", "forgot")
    }
  },

  computed: {
    userLogInStatus() {
      return this.$store.getters["user/isLoggedIn"]
    },

    userUuid() {
      return this.$store.state.user.profile.uuid
    }
  },

  watch: {
    userUuid(newValue) {
      if (newValue !== "") {
        this.$store.dispatch("bus/closePopup", "login")

        // if (this.$store.state.bus.wantsToDownloadReceipt) {
        //   this.$store.dispatch("bus/change", {
        //     name: 'wantsToDownloadReceipt',
        //     value: false
        //   })
        //   this.$store.dispatch("bus/openPopup", "receipt")
        //   return
        // }


        this.$router.push("/personal/profile")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

$input-border-color: #DBDBDB;
$input-bg-color: #FFFFFF;
$input-text-color: #666;

.error-hint {
  font-size: 12px;
  font-weight: 400;
  font-family: "Marvin Round";
  color: #3F3F3F;
}

.login {
  &__form {
    text-align: center;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin: 10px 0 10px 0;
  }

  &__input {
    box-sizing: border-box;
    width: 376px;
    height: 60px;
    padding-left: 30px;
    border: 2px solid $input-border-color;
    background-color: $input-bg-color;
    font-family: "Montserrat";
    font-size: 20px;
    border-radius: 50px;
    color: $input-text-color;
  }

  &__input-error {
    border-color: $error-color;
  }

  &__button-group {
    padding-bottom: 60px;

    button {
      display: block;
    }
  }

  &__forgot-password {
    margin-bottom: 10px;
  }

  &__button {
    width: 376px;
    height: 60px;
    font-family: "Marvin Round";
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }

  &__button-main {
    background-color: $purple;
    color: $white;

    &:hover {
      background-color: $purple;
    }
  }

  &__button-secondary {
    margin-top: 20px;
    background-color: $white;
    color: $purple;
    border: 3px solid $purple;
  }
}

@media screen and (max-width: $lg) {
  .login {
    &__input-group {
      row-gap: 10px;
    }

    &__input {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      font-size: 16px;
    }

    &__forgot-password {
      font-size: 18px;
    }

    &__button-group {
      width: 100%;
    }

    &__button {
      width: 100%;
      height: 50px;
      font-size: 14px;
    }
  }
}
</style>