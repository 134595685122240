<template>
  <modal @oauth="fillDataSet" @close="close" has-footer>
    <h2>Регистрация</h2>
    <form @submit.prevent="register" class="register__form" method="post">
      <div class="register__input-block">
        <div class="register__input-group">
          <div class="register__input-block">
            <input v-model="name" :class="{ 'register__input-error': hasError('name') }"
                   @keydown="removeError('name')"
                   type="text" class="register__input" id="name" name="name" placeholder="Имя">
            <div v-show="hasError('name')" class="error-hint">{{ errors.name }}</div>
          </div>
          <div class="register__input-block">
            <input v-model="email" :class="{ 'register__input-error': hasError('email') }"
                   @keydown="removeError('email')"
                   type="email" class="register__input" id="email" name="email" placeholder="E-mail">
            <div v-show="hasError('email')" class="error-hint">{{ errors.email }}</div>
          </div>
        </div>
        <div class="register__input-group">
          <div class="register__input-block">
            <input v-model="phone" :class="{ 'register__input-error': hasError('phone') }" v-mask="'+7(###) ###-##-##'"
                   @keydown="removeError('phone')"
                   type="tel" class="register__input" id="phone" name="phone" placeholder="Телефон">
            <div v-show="hasError('phone')" class="error-hint">{{ errors.phone }}</div>
          </div>
          <div class="register__input-block">
            <date-picker v-model="birthDate" :input-attr="{ id: 'birth-date' }" :type="'date'" :format="'DD.MM.YYYY'"
                         :placeholder="'Дата рождения'" :editable="false" @focus="removeError('birthDate')" />
            <div v-show="hasError('birthDate')" class="error-hint">{{ errors.birthDate }}</div>
          </div>
        </div>
        <div class="register__input-group">
          <div class="register__input-block">
            <input v-model="password" :class="{ 'register__input-error': hasError('password') }"
                   @keydown="removeError('password')"
                   type="password" class="register__input" id="password" name="password" placeholder="Пароль">
            <div v-show="hasError('password')" class="error-hint">{{ errors.password }}</div>
          </div>
          <div class="register__input-block">
            <input v-model="passwordConfirm"  :class="{ 'register__input-error': hasError('passwordConfirm') }"
                   @keydown="removeError('passwordConfirm')"
                   type="password" class="register__input" id="password-confirmation"
                   name="password-confirmation" placeholder="Подтверждение пароля">
            <div v-show="hasError('passwordConfirm')" class="error-hint">{{ errors.passwordConfirm }}</div>
          </div>
        </div>
      </div>
      <div class="register__checkbox-group">
        <label @click="toggleMailingCheckbox"
               :class="this.isMailingAgreed ? 'register__enabled-checkbox' : 'register__disabled-checkbox'"
               for="agreement-mailing">
          Согласие на получение рассылок
        </label>
        <input type="checkbox" id="agreement-mailing" name="agreement-mailing">
        <div v-show="hasError('isMailingAgreed')" class="error-hint">{{ errors.isMailingAgreed }}</div>
      </div>
      <div class="register__checkbox-group">
        <label @click="toggleRulesCheckbox"
               :class="this.isRulesAgreed ? 'register__enabled-checkbox' : 'register__disabled-checkbox'"
               for="agreement-rules">
          Согласие с <a target="_blank" href="https://dobry-academia.ru/rules.pdf">правилами акции</a>, <a target="_blank" href="https://dobry-academia.ru/agreement.pdf">пользовательским соглашением</a>
        </label>
        <input type="checkbox" id="agreement-rules" name="agreement-rules">
        <div v-show="hasError('isRulesAgreed')" class="error-hint">{{ errors.isRulesAgreed }}</div>
      </div>
      <button class="register__button register__button-main" type="submit">Зарегистрироваться</button>
    </form>
  </modal>
</template>

<script>
import LargePopup from "@/components/popups/layouts/LargePopup.vue"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

export default {
  name: "RegisterPopup",
  components: {
    Modal: LargePopup,
    DatePicker
  },

  data() {
    return {
      name: "",
      email: "",
      phone: "",
      birthDate: null,
      password: "",
      passwordConfirm: "",
      isRulesAgreed: false,
      isMailingAgreed: false,
      soc: {},

      errors: {
        name: false,
        email: false,
        birthDate: false,
        phone: false,
        password: false,
        passwordConfirm: false,
        isRulesAgreed: false,
        isMailingAgreed: false
      }
    }
  },

  methods: {
    close() {
      this.$store.dispatch("bus/closePopup", "register")
    },

    toggleRulesCheckbox() {
      this.isRulesAgreed = ! this.isRulesAgreed
    },

    toggleMailingCheckbox() {
      this.isMailingAgreed = ! this.isMailingAgreed
    },

    hasError(field) {
      return this.errors[field]
    },

    removeError(field) {
      if (field === 'birthDate') {
        const el = document.getElementById('birth-date')

        el.style.borderColor = '#DBDBDB'

        return
      }

      this.errors[field] = false
    },

    validate() {
      let error = false

      if (this.name === "") {
        this.errors.name = error = "Поле имя является обязательным"
      }

      if (this.email === "") {
        this.errors.email = error = "Поле E-mail является обязательным"
      }

      if (this.phone === "" || this.phone.length !== 17) {
        this.errors.phone = error = "Поле телефон является обязательным"
      }

      if (this.birthDate === null) {
        const el = document.getElementById('birth-date')

        el.style.borderColor = '#FF4646'

        this.errors.birthDate = error = "Поле дата рождения является обязательным"
      }

      if (this.password === "") {
        this.errors.password = error = "Поле пароль является обязательным"
      }

      if (this.passwordConfirm === "") {
        this.errors.passwordConfirm = error = "Поле подтверждение пароля является обязательным"
      }

      if (this.password !== this.passwordConfirm) {
        this.errors.passwordConfirm = this.errors.password = error = "Пароли не совпадают"
      }

      if (! this.isRulesAgreed) {
        this.errors.isRulesAgreed = error = "Согласие обязательно"
      }

      if (! this.isMailingAgreed) {
        this.errors.isMailingAgreed = error = "Согласие обязательно"
      }

      return ! error
    },

    register() {
      if (! this.validate()) {
        return
      }

      const tzOffset = this.birthDate.getTimezoneOffset() * 60000
      const local = (new Date(this.birthDate - tzOffset))

      local.setUTCMilliseconds(0)

      const localISODate = local.toISOString().replace(".000Z", "+00:00")

      const request = {
        name: this.name,
        login: this.email,
        phone: this.phone,
        birthDate: localISODate,
        password: this.password,
        passwordConfirm: this.passwordConfirm,
      }

      const props = Object.keys(this.soc)

      if (props.length !== 0) {
        request.socId = this.soc.id
        request.socType = this.soc.type

        this.$store.dispatch("user/signUpSoc", request).then((response) => {
          if (response.error === 1) {
            const message = response.message

            if ("name" in message) {
              this.errors.name = message.name[0]
            }

            if ("login" in message) {
              this.errors.email = message.login[0]
            }

            if ("pass" in message) {
              this.errors.password = message.pass[0]
            }

            if ("phone" in message) {
              this.errors.phone = message.phone[0]
            }

            if ("birth_date" in message) {
              this.errors.birthDate = message.birth_date[0]
            }

            return;
          }

          if (! response) {
            return
          }

          this.$store.dispatch("bus/notifySuccess", {
            title: "Успешно",
            message: "Вы были успешно зарегистрированы, на вашу почту было отправлено письмо с подтверждением"
          })
        })

        return
      }

      this.$store.dispatch("user/signUp", request).then((response) => {
        if (response.error === 1) {
          const message = response.message

          if ("name" in message) {
            this.errors.name = message.name[0]
          }

          if ("login" in message) {
            this.errors.email = message.login[0]
          }

          if ("phone" in message) {
            this.errors.phone = message.phone[0]
          }

          if ("pass" in message) {
            this.errors.password = message.pass[0]
          }

          if ("birth_date" in message) {
            this.errors.birthDate = message.birth_date[0]
          }

          return;
        }

        if (! response) {
          return
        }

        // this.$notify({
        //   text: "Ты был успешно зарегистрирован, на твою почту было отправлено письмо с подтверждением.",
        //   type: "success"
        // })
        this.$store.dispatch("bus/notifySuccess", {
          title: "Успешно",
          message: "Ты был успешно зарегистрирован, на твою почту было отправлено письмо с подтверждением"
        })
      })
    },

    fillDataSet(user) {
      this.name = user.first_name + " " + user.last_name
      this.soc = {
        id: user.id,
        type: "vk"
      }
    }
  },

  computed: {
    userUuid() {
      return this.$store.state.user.profile.uuid
    }
  },

  watch: {
    userUuid(newValue) {
      if (newValue !== "") {
        this.$store.dispatch("bus/closePopup", "register")

        this.$router.push("/personal/profile")
      }
    }
  },

  mounted() {
    this.name = this.$store.state.bus.oauth.name
    this.email = this.$store.state.bus.oauth.email
    this.phone = this.$store.state.bus.oauth.phone
    this.soc = this.$store.state.bus.oauth.soc
    this.birthDate = this.$store.state.bus.oauth.birthDate

    this.$store.dispatch("bus/fillOAuth", {
      name: "",
      email: "",
      phone: "",
      soc: {},
      birthDate: null
    })
  }
}
</script>

<style>
.error-hint {
  font-size: 12px;
  font-weight: 400;
  font-family: "Marvin Round";
  color: #3F3F3F;
}

.mx-datepicker {
  width: 100%;
}

.mx-input {
  box-sizing: border-box;
  height: 60px;
  padding: 0 0 0 30px;
  border: 2px solid #DBDBDB;
  background-color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 20px;
  border-radius: 50px;
  color: #666;
  //text-transform: uppercase;
}

.mx-icon-calendar {
  top: 10px;
  right: 54px;
}

.mx-icon-calendar svg {
  display: none;
}

.mx-icon-calendar::before {
  content: "";
  position: absolute;
  display: block;
  width: 34px;
  height: 38px;
  top: 0;
  left: 0;
  background-image: url("../../assets/svg/icon-calendar.svg");
  background-size: cover;
}

@media screen and (max-width: 992px) {
  .mx-datepicker {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .mx-input {
    height: 50px;
    font-size: 16px;
  }

  .mx-icon-calendar {
    top: 13px;
    right: 40px;
  }

  .mx-icon-calendar::before {
    width: 20px;
    height: 22px;
  }
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

$input-border-color: #DBDBDB;
$input-bg-color: #FFFFFF;
$input-text-color: #666;

.register {
  &__form {
    text-align: center;
  }

  &__input-block {
    margin-bottom: 34px;
  }

  &__input-group {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin: 10px 0 10px 0;
  }

  &__checkbox-group {
    position: relative;
    margin-top: 24px;
    padding-left: 40px;
    text-align: left;

    label {
      cursor: pointer;
    }

    input[type="checkbox"] {
      display: none;
    }
  }

  &__enabled-checkbox::before, &__disabled-checkbox::before {
    position: absolute;
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    border-radius: 10px;
  }

  &__enabled-checkbox::before {
    background-size: cover;
    background-image: url("../../assets/svg/icon-enabled-checkbox.svg");
  }

  &__disabled-checkbox::before {
    border: 1px solid $input-border-color;
    background-color: $input-bg-color;
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding-left: 30px;
    border: 2px solid $input-border-color;
    background-color: $input-bg-color;
    font-family: "Montserrat";
    font-size: 20px;
    border-radius: 50px;
    color: $input-text-color;
    //text-transform: uppercase;
  }

  &__input-block {
    width: 100%;
  }

  &__input-error {
    border-color: $error-color;
  }

  &__button {
    height: 60px;
    margin: 30px 0 20px 0;
    padding: 0 80px 0 80px;
    font-family: "Marvin Round";
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    border-radius: 50px;
    cursor: pointer;
  }

  &__button-main {
    background-color: $purple;
    color: $white;

    &:hover {
      background-color: $purple;
    }
  }
}

@media screen and (max-width: $lg) {
  .register {
    &__input-group {
      display: block;
      margin: 0;
    }

    &__input-block {
      width: 100%;
    }

    &__input {
      display: block;
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      font-size: 16px;
    }

    &__button {
      width: 100%;
      height: 50px;
      font-size: 14px;
    }
  }
}
</style>