<template>
  <div>
    <div class="modal__bg"></div>
    <div class="modal__wrapper">
      <div class="modal__window">
        <div class="modal__content">
          <img @click="close" src="../../../assets/svg/close-icon.svg" alt="Закрыть" class="modal__close-icon">
          <slot></slot>
        </div>
        <div v-if="hasFooter" class="modal__footer">
          <div class="modal__login">Зарегистрироваться через</div>
          <div>
            <img @click="vkLogin" src="../../../assets/svg/icon-ellipse-vk.svg" alt="VK" style="cursor: pointer;">
            <img @click="okLogin" src="../../../assets/svg/icon-ellipse-odn.svg" alt="Одноклассники" style="cursor: pointer;">
            <img @click="googleLogin" src="../../../assets/svg/icon-ellipse-google.svg" alt="Google" style="cursor: pointer;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vk from "vk-openapi"

export default {
  props: {
    hasFooter: {
      type: Boolean
    }
  },
  name: "LargePopup",

  methods: {
    close() {
      this.$emit("close")
    },

    vkLogin() {
      vk.init({
        apiId: 8180215
      })

      vk.Auth.login(async (data) => {
        if (data.session === null) {
          this.$store.dispatch("bus/closePopup", "register")

          this.$store.dispatch("bus/notifyError", {
            title: "Ошибка",
            message: "Произошла ошибка во время авторизации"
          })

          return
        }

        const response = await this.$store.dispatch("user/signInSoc", {
          id: data.session.user.id,
          type: "vk"
        })

        if (response.error === 0) {
          this.$store.dispatch("bus/closePopup", "register")
          this.$router.push("/personal/profile")

          return;
        }

        this.$emit("oauth", data.session.user)
      }, 1)
    },

    okLogin() {
      window.location.href = "https://connect.ok.ru/oauth/authorize?client_id=512001153432&scope=VALUABLE_ACCESS;LONG_ACCESS_TOKEN;GET_EMAIL&response_type=token&redirect_uri=https://dobry-academia.ru/oauth/ok/callback"
    },

    googleLogin() {
      window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?client_id=144039525783-65gbo9rsq9rv20mi7ph1md2cqcv5n9gr.apps.googleusercontent.com&redirect_uri=https://dobry-academia.ru/oauth/google/callback&response_type=token&scope=openid profile email"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables";

$background-color: #3F3F3F;
$window-color: #FFF;

a {
  text-decoration: none;
  color: $purple;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 50px;
  text-align: center;
  color: $purple;
}

.modal {
  &__bg {
    position: fixed;
    background-color: $background-color;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 998;
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    justify-content: center;
    overflow-y: scroll;
  }

  &__window {
    box-sizing: border-box;
    position: relative;
    width: 800px;
    background-color: #FFF;
    border-radius: 40px;
    opacity: 1;
    font-family: "Marvin Round";
    text-transform: uppercase;
    height: auto;
    margin: 0 auto;
    margin-top: 25px;
  }

  &__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__close-icon {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__footer {
    padding: 40px 0 30px 0;
    //background-image: url("../../../assets/svg/modal-footer.svg");
    background-color: #C8C8C8;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    text-align: center;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    img {
      &:nth-child(2) {
        margin: 0 27px 0 27px;
      }
    }
  }

  &__login {
    margin-bottom: 30px;
    font-size: 30px;
    color: #3F3F3F;
  }
}

@media screen and (max-width: $lg) {
  h2 {
    font-size: 30px;
  }

  .modal {
    &__wrapper {
      width: 100%;
    }

    &__window {
      padding-top: 100px;
      border-radius: 0;
      width: auto;
    }

    &__close-icon {
      top: 80px;
    }

    &__footer {
      background-image: url("../../../assets/svg/modal-footer-mob.svg");
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      img {
        width: 60px;
        height: 60px;

        &:nth-child(2) {
          margin: 0 18px 0 18px;
        }
      }
    }

    &__login {
      font-size: 16px;
    }
  }
}
</style>